<template>

  <dialog
    ref="imagePreviewModal"
    id="imagePreviewModal"
    class="modal"
    @close="modalClosed"
  >

    <div class="modal-box sm:max-w-4xl md:max-w-7xl p-3">

      <div class="w-full flex flex-col sm:flex-row">
        <img
          :src="'https://ik.imagekit.io/aicosmetic/'+exampleObj.src"
          width="1024"
          height="1024"
          fit="contain"
          class="w-full sm:w-2/3 rounded-lg"
        />
        <div class="w-full sm:w-1/3 flex flex-col">

          <div class="p-3 grid grid-cols-2 gap-2 sm:grid-cols-1">
            <div class="text-left">
              <p class="text-xl font-bold">Style</p>
              <p>
                {{ exampleObj.style }}
              </p>
            </div>

            <nuxt-link
              :to="exampleObj.style_link"
              class="btn btn-neutral"
            >
              Learn more
            </nuxt-link>

            <nuxt-link
              :to="'/hair?' + exampleObj.hair_changer_queryparam"
              class="btn btn-primary"
            >
              Try this style
            </nuxt-link>

          </div>

          <div class="flex pt-2 justify-between mt-auto">
            <div
              class="tooltip tooltip-info tooltip-right"
              data-tip="Delete this image"
            >

            </div>
            <form
              method="dialog"
              class="flex mr-0"
            >
              <button class="btn btn-ghost mr-1">Close</button>
            </form>
          </div>

        </div>
      </div>

    </div>

    <form
      method="dialog"
      class="modal-backdrop"
    >
      <button>close</button>
    </form>

  </dialog>


</template>

<style scoped>

  
</style>

<script setup>


const imagePreviewModal = ref(null)
const emit = defineEmits(['closeModal'])

const props = defineProps({

  exampleObj: {
    type: Object,
    required: true
  }
})


onMounted(() => {
  console.log("example image modal loaded, props:", props)
  setTimeout(() => {
    imagePreviewModal.value.showModal()
  }, 100)
})

function closeModal() {
  console.log("Closing alert modal")
  imagePreviewModal.value.close()
}

function modalClosed() {
  console.log("image modal closed")

  setTimeout(() => {
    emit('closeModal')
  }, 200)
}

</script>