<template>
  <div class="w-full relative pb-20">

    <div class="w-full prose max-w-full mx-auto">

      <div class="px-5 flex flex-col md:flex-row max-w-7xl m-auto">

        <div class="w-full md:w-1/3 text-center md:text-left flex flex-col justify-center mt-10">
          <h1 class="gradient-text-1 font-black">
            AI hairstyles for men and women
          </h1>
          <h2 class="mt-1">
            Try different hairstyles and colours in seconds!
          </h2>

          <div class="mt-3 flex flex-col justify-start">
            <NuxtLink
              class="btn m-1 btn-primary md:mt-0 w-full mx-auto"
              to="/hair"
            >
              Get started
            </NuxtLink>

            <div class="mt-2 grid gap-2">
              <!-- <NuxtLink
                class="btn btn-secondary btn-outline w-full md:mt-0"
                to="/studio"
              >
                Try Studio (beta)
              </NuxtLink> -->
              <NuxtLink
                class="btn btn-secondary btn-neutral w-full md:mt-0 hidden"
                to="/hair-colour-changer"
              >
                Hair Colour Changer
                <ThebetaTag :options="{ navmode: true }" />
              </NuxtLink>
              <!-- <NuxtLink
                class="btn btn-secondary btn-neutral w-full md:mt-0"
                to="/ai-face-shape-analysis-hairstyle-recommendations"
              >
                Face Shape Analysis (coming soon)
              </NuxtLink> -->
            </div>
          </div>

        </div>

        <div class="md:w-2/3">
          <div>

            <div
              class="flex justify-center"
              @click="loadVideo"
              v-if="!videoLoaded"
              style="height: 800px;"
            >
              <nuxt-img
                src="/AAA-hairstyle_images/homepage/video-thumbnail.png"
                height="800"
                densities="x1 x2"
                class="rounded-lg shadow-2xl cursor-pointer hover:opacity-75 transition-opacity"
                loading="lazy"
                alt="How AI hairstyles works in 30 seconds"
                :modifiers="{}"
              />
            </div>
            <div
              v-else
              class="flex justify-center"
            >
              <div class="relative">
                <video
                  ref="demoVideo"
                  class="rounded-lg shadow-2xl h-full"
                  style="max-height: 800px;"
                  height="800"
                  controls
                  autoplay
                  @ended="showVideoend = true"
                  src="https://ik.imagekit.io/aicosmetic/AAA-hairstyle_images/homepage/short_demo_1.mp4?updatedAt=1722981253492"
                >
                  <source
                    src="https://ik.imagekit.io/aicosmetic/AAA-hairstyle_images/homepage/short_demo_1.mp4?updatedAt=1722981253492"
                    type="video/mp4"
                  >
                  Your browser does not support the video tag.
                </video>
                <div
                  v-if="showVideoend"
                  class="absolute top-0 w-full h-full flex justify-center items-center bg-base-200 bg-opacity-85"
                >
                  <div>
                    <NuxtLink
                      class="btn m-1 btn-primary md:mt-0 w-full mx-auto"
                      to="/hair"
                    >
                      Get started
                    </NuxtLink>
                    <button
                      class="btn btn-secondary btn-outline w-full md:mt-0"
                      @click="loadVideo"
                    >
                      Replay video
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <img
            v-if="colorScheme === 'myLight'"
            id="hero-image-light"
            src="https://ik.imagekit.io/aicosmetic/AAA-hairstyle_images/homepage/homepage-light"
            width="800"
            height="610"
            fit="contain"
            class="mx-auto"
            alt="Sequence of images showing a user's selfie and the resulting AI generated images with varying hairstyles, for both male and female styles. Styles shown are crew cut, mullet, bald, french crop, long wavy, pixie cut"
          />
          <img
            v-if="colorScheme === 'myDark'"
            id="hero-image-dark"
            src="https://ik.imagekit.io/aicosmetic/AAA-hairstyle_images/homepage/homepage-dark"
            width="800"
            height="610"
            fit="contain"
            class="mx-auto"
            alt="Sequence of images showing a user's selfie and the resulting AI generated images with varying hairstyles, for both male and female styles. Styles shown are crew cut, mullet, bald, french crop, long wavy, pixie cut"
          /> -->
        </div>

        <div
          class="hidden w-full"
          style="min-height: 1000px;"
          v-if="isMobile"
        >
          <img
            v-if="colorScheme === 'myLight'"
            id="hero-image-light"
            src="https://ik.imagekit.io/aicosmetic/AAA-hairstyle_images/homepage/homepage-light-mobile"
            width="800"
            height="1870"
            fit="contain"
            class="mx-auto"
            alt="Sequence of images showing a user's selfie and the resulting AI generated images with varying hairstyles, for both male and female styles. Styles shown are crew cut, mullet, bald, french crop, long wavy, pixie cut"
          />
          <img
            v-if="colorScheme === 'myDark'"
            id="hero-image-dark"
            src="https://ik.imagekit.io/aicosmetic/AAA-hairstyle_images/homepage/homepage-dark-mobile"
            width="800"
            height="1870"
            fit="contain"
            class="mx-auto"
            alt="Sequence of images showing a user's selfie and the resulting AI generated images with varying hairstyles, for both male and female styles. Styles shown are crew cut, mullet, bald, french crop, long wavy, pixie cut"
          />
        </div>
      </div>


      <div class="mt-20 text-center max-w-7xl m-auto hidden">

        <h2 class="text-center">How It Works</h2>

        <ul class="p-0 steps">
          <li class="step step-primary">Upload a selfie</li>
          <li class="step step-primary">
            Choose your styles and colours
          </li>
          <li class="step step-primary">
            We'll generate your realistic previews
          </li>
        </ul>

        <!-- <div class="grid grid-cols-1 sm:grid-cols-3">
          <div>
            <nuxt-img
              src="/AAA-hairstyle_images/homepage/1-upload-selfie"
              width="300"
              height="300"
              sizes="40vw sm:50vw md:244px 100px"
              densities="x1 x2"
              :placeholder="[300, 300]"
              loading="lazy"
              alt="Upload a selfie"
              :modifiers="{ }"
            />
            <p class="mt-3">
              Upload a selfie (up to 4)
            </p>
          </div>
          <div>
            <nuxt-img
              src="/AAA-hairstyle_images/homepage/2-mask-hair"
              width="300"
              height="300"
              sizes="40vw sm:50vw md:244px 100px"
              densities="x1 x2"
              :placeholder="[300, 300]"
              loading="lazy"
              alt="Face shape analysed"
              :modifiers="{ }"
            />
            <p class="mt-3">
              We'll analyse your image for face shape
            </p>
          </div>
          <div>
            <nuxt-img
              src="/AAA-hairstyle_images/homepage/3-choose-style"
              width="300"
              height="300"
              sizes="40vw sm:50vw md:244px 100px"
              densities="x1 x2"
              :placeholder="[300, 300]"
              loading="lazy"
              alt="Choose your style(s), colour(s)"
              :modifiers="{ }"
            />
            <p class="mt-3">
              Choose a style and colour
            </p>
          </div>
          <div>
            <nuxt-img
              src="/AAA-hairstyle_images/homepage/4-wait"
              width="300"
              height="300"
              sizes="40vw sm:50vw md:244px 100px"
              densities="x1 x2"
              :placeholder="[300, 300]"
              loading="lazy"
              alt="Wait for the preview"
              :modifiers="{ }"
            />
            <p class="mt-3">
              We'll generate your previews
            </p>
          </div>
        </div> -->


      </div>

      <div class="mt-20 text-center styles max-w-7xl m-auto hidden">

        <h2 class="text-center">Available Styles</h2>

        <!-- <div class="not-prose">
          <ul class="menu menu-horizontal bg-base-200 rounded-box">
            <li class="mx-1">
              <button
                :class="{'gradient-text-1': selectedGender =='masculine'}"
                @click="selectGender('masculine')"
              >
                Man
              </button>
            </li>
            <li class="mx-1">
              <button @click="selectGender('feminine')">
                <span :class="{'gradient-text-1': selectedGender =='feminine'}">
                  Woman
                </span>
              </button>
            </li>
          </ul>
        </div> -->

        <TheJsonPretty :theData="filteredStyles" />

        <div class="grid not-prose grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 mt-5 p-2">

          <div
            class=""
            v-for="style in styles"
          >
            <NuxtLink
              :to="`/hairstyles/${style.slug}`"
              class="relative block hover:opacity-75 transition-opacity"
            >
              <nuxt-picture
                class="w-full"
                :src="generateImagePreviewURL(style.preview_images)"
                width="400"
                height="400"
                sizes="40vw sm:50vw md:400px 100px"
                densities="x1 x2"
                :placeholder="[600, 600]"
                loading="lazy"
                :alt="styleAltText(style)"
                :modifiers="{}"
              />
              <div class="imageLabel">
                <span>{{ style.description }}</span>
              </div>
            </NuxtLink>
          </div>
          <div>
            <NuxtLink
              :to="`/request`"
              class="relative block hover:opacity-75 transition-opacity request-box aspect-square"
            >
              <img
                src="@/assets/icons/circle-plus.svg"
                alt="Request style"
                width="50"
                class="icon opacity-25"
              />
              <span class="mt-2">Request a style</span>
            </NuxtLink>
          </div>
        </div>
      </div>

      <div class="examples mt-20">
        <h2 class="text-center">Examples</h2>

        <div class="flex flex-col sm:flex-row items-center sm:items-start p-2">

          <div class="not-prose mt-5 grid grid-cols-2 md:grid-cols-4 gap-2 m-auto">


            <div
              class="relative"
              v-for="example in examples"
            >
              <nuxt-img
                class="rounded-lg shadow-2xl cursor-pointer hover:opacity-75 transition-opacity"
                :src="example.src"
                width="300"
                height="300"
                sizes="40vw sm:50vw md:400px 100px"
                densities="x1 x2"
                :placeholder="[300, 300]"
                loading="lazy"
                :alt="example.alt"
                @click="exampleImagePreviewModal.show = true; exampleImagePreviewModal.exampleObj = example"
              />

            </div>

          </div>

        </div>

      </div>

      <div class="mt-20 max-w-7xl m-auto">
        <h2 class="text-center">
          Latest News
        </h2>
        <p class="text-center">
          <nuxt-link
            to="/blog"
            class="btn btn-sm btn-ghost mt-2"
          >
            View all posts
          </nuxt-link>
        </p>
        <div
          class="grid grid-cols-1 md:grid-cols-3 gap-3 my-2 w-full not-prose h-full"
          v-if="featuredPosts"
        >

          <NuxtLink
            :to="article._path"
            v-for="article in featuredPosts"
            :key="article._path"
            class=" shadow-2xl card hover:shadow transition-shadow bg-base-200"
          >
            <div class="flex flex-col h-full ">
              <div class="w-full p-3">

                <nuxt-img
                  v-if="article.img"
                  :src="`${article.img}`"
                  width="350"
                  height="180"
                  fit="cover"
                  class="m-0 rounded-lg w-full"
                  loading="lazy"
                  :alt="'Featured image for the blog post of the title: ' + article.title"
                />

              </div>

              <div class="p-5 pt-2 not-prose grow flex flex-col">
                <h2
                  class="text-2xl font-semibold"
                  style="text-decoration: none !important;"
                >{{ article.title }}</h2>
                <div class="flex flex-col h-full">
                  <p class="pt-4 base-200-text">{{ article.description }}</p>
                  <!-- <p class="text-sm mt-3 justify-self-end flex-1 flex items-end base-200-text">
                    {{ article.date }}
                  </p> -->
                </div>
              </div>

            </div>
          </NuxtLink>


        </div>
      </div>


      <div class="examples mt-20 w-full max-w-7xl m-auto p-5">
        <h2 class="text-center">Why AIhairstyles?</h2>

        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5">

          <div>
            <h3>
              Works with just <strong>one selfie</strong>
            </h3>
            <h4 class="muted">
              No need for multiple photos in different poses and lighting
            </h4>
            <p>
              We've invested heavily in our proprietary AI and custom-trained models to make sure that you get great
              results with just one selfie, unlike other apps that require you to take lots of different photos in
              different poses and lighting. Honestly, who has time for that?
            </p>
          </div>

          <div>
            <h3>
              Privacy-first (we're based in the EU)
            </h3>
            <h4 class="muted">
              We delete your images after 30 days of inactivity automatically
            </h4>
            <p>
              And, you can delete them instantly from your profile page at any time.
              We don't use your images for anything other than showing you what you'd look like with different
              hairstyles. We don't sell your data or images to anyone.
            </p>
          </div>

          <div>
            <h3>
              It's quick
            </h3>
            <h4 class="muted">
              Usually seconds to generate!
            </h4>
            <p>
              Often, previews take seconds to generate. But sometimes, it can take a little longer if the site has a
              lot
              of visitors. We'll always email you when your previews are ready.
            </p>
          </div>

        </div>

      </div>

      <div
        class="mt-40 text-center max-w-3xl m-auto"
        id="pricing"
      >
        <h2>Pricing</h2>


        <div class="mt-5">
          <p class="font-bold text-lg mb-2">
            Packs
          </p>
          <div class="grid grid-cols-1 sm:grid-cols-2 gap-3">
            <div
              class="w-full flex flex-col shadow-xl bg-base-200 rounded-xl p-5"
              v-for="productPackage in packages"
            >
              <h2 class="text-2xl">
                {{ productPackage.title }}
              </h2>
              <h3 class="my-2">
                {{ productPackage.description }}
              </h3>
              <p class="gradient-text-1 font-bold text-2xl">
                ${{ productPackage.price }}
              </p>
              <p
                class="my-2"
                v-for="para in productPackage.descriptionParagraphs"
              >
                {{ para }}
              </p>
            </div>
          </div>
        </div>

        <p class="font-bold text-lg mb-2">
          Or, you can pick-and-mix with the credit system
        </p>

        <p>
          Each style costs just <strong>1 credit.</strong> You'll receive <span class="font-bold">2 images per style
          </span>
        </p>
        <p>1-50 credits for
          <span class="font-bold">69c</span> each
        </p>
        <p>
          50-150 credits for <span class="font-bold">49c</span> each
        </p>
        <p>
          150+ credits for <span class="font-bold">39c</span> each
        </p>
        <p>
          Minimum purchase is 10 credits. Credits don't expire, you don't have to use them all at once and you can
          re-generate styles using different images of yourself or friends/family if you like.
        </p>
        <p class="italic">
          PS: You can earn credits by referring friends too!
        </p>

      </div>

      <div class="mt-40 text-center max-w-3xl m-auto">
        <h2>FAQ</h2>
        <div>
          <p>
            <span class="font-bold">Why do I have to log in?</span>
            <br>
            You'll need to upload images. In order to ensure data privacy, we need to connect these to an account.
            This
            also means that at any time, you can delete ALL of your data from the /profile page. This deletes
            everything, including your entire account, so use with caution.
          </p>
          <hr class="muted max-w-xs m-auto my-5">
          <p>
            <span class="font-bold">How long does it take?</span>
            <br>
            This can depend on the load, but typically it takes a max of about ~20 minutes. If you're seeing it take
            longer than an hour, please get in touch and we'll check what's happening
          </p>
          <hr class="muted max-w-xs m-auto my-5">
          <p>
            <span class="font-bold">Does it work with female styles, or for different ethnicities and races?</span>
            <br>
            Usually yes, but sometimes it can need a little tweaking. If you're in any way unhappy with the results,
            just get in touch and we'll either re-run them with tweaks or issue a refund.
          </p>
          <hr class="muted max-w-xs m-auto my-5">
          <p>
            <span class="font-bold">What if I don't like the results? Can I get a refund?</span>
            <br>
            If you're in any way unsatisfied with your results, just <NuxtLink
              to="/contact"
              class="link"
            >get in touch</NuxtLink>. We'll look into what might have gone wrong and either re-run them with tweaks or
            issue a refund.
          </p>
        </div>
      </div>

      <div class="mt-40 text-center max-w-3xl m-auto p-5">
        <h2>AI for barbers and salons</h2>

        <h3>
          Give your clients a next-level AI experience
        </h3>

        <div class="rounded-lg shadow-2xl">
          <video
            src="https://ik.imagekit.io/aicosmetic/marketing-general/sneak-peek-1.mp4?updatedAt=1708990576272"
            controls
            preload="none"
            class="rounded-lg w-full"
            poster="https://ik.imagekit.io/aicosmetic/marketing-general/video-still.webp"
          >
          </video>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-2 gap-3 ">
          <NuxtLink
            to="/barbers"
            class="btn btn-outline"
          >
            AI for Barbers
          </NuxtLink>

          <NuxtLink
            to="/salons"
            class="btn btn-outline"
          >
            AI for Salons
          </NuxtLink>
        </div>
      </div>

    </div>

    <TheAuthModal
      v-if="authModal.show"
      :modalData="authModal"
      @closeModal="(authModal.show = false)"
    />

    <TheExampleImagePreviewModal
      v-if="exampleImagePreviewModal.show"
      :exampleObj="exampleImagePreviewModal.exampleObj"
      @closeModal="exampleImagePreviewModal.show = false"
    />


  </div>
</template>

<style scoped>
[data-theme='myDark'] {
  #hero-image-light {
    display: none;
  }
}

[data-theme='myLight'] {
  #hero-image-dark {
    display: none;
  }
}

.imageLabel {
  position: absolute;
  bottom: 0px;
  right: 0px;
  /* From https://css.glass */
  background: rgb(0 0 0 / 38%);
  border-radius: 0.25rem 0px 0.25rem 0px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.5px);
  -webkit-backdrop-filter: blur(7.5px);
  min-width: 40%;
  font-weight: 600;
  @apply px-2 py-1 text-center text-yellow-50 text-sm;
}

.examples picture,
.styles picture img,
.styles picture source {
  @apply rounded shadow-2xl;
}
</style>

<script setup>

const isMobile = useIsMobile()
const colorScheme = useColorScheme()
const { $dpr } = useNuxtApp()
const styles = ref(null)
const images = ref({})
const cachedStyles = useNuxtData('styles')
const featureFlags = useFeatureFlags()
const selectedGender = useSelectedGender()
const supabaseUser = useSupabaseUser()
const supabaseUserData = useSupabaseUserData()
const checkoutRef = ref(null)
const publishableKey = ref(useRuntimeConfig().public.STRIPE_PUBLISHABLE_KEY)
const lineItems = ref([
  {
    price: useRuntimeConfig().public.STRIPE_ALL_STYLES_PACKAGE, // The id of the one-time price you created in your Stripe dashboard
    quantity: 1,
  },
])
const stripe = ref({
  isProcessing: false,
  error: null,
  token: null,
  cardNumber: null,
  cardExpiry: null,
  cardCvc: null,
  elements: null,
  clientSecret: null,
})
const cardNumberElement = ref(null)
const cardExpiryElement = ref(null)
const cardCvcElement = ref(null)
const stripeElementsTest = ref(null)
const clientSecret = useClientSecret()
const creditTopUpModal = ref({
  show: false,
  data: {
    mode: "all-styles-package",
    result: {
      success: null,
      amount: null
    }
  }
})
const authModal = ref({
  show: false,
  mode: ""
})
const featuredPosts = ref(null)
const products = useProducts()

const exampleImagePreviewModal = ref({
  show: false,
  styleObject: null
})

const videoLoaded = ref(false)
const showVideoend = ref(false)
const demoVideo = ref(null)
function loadVideo() {

  showVideoend.value = false

  videoLoaded.value = true

  if (demoVideo.value) {
    demoVideo.value.play()
    demoVideo.value.currentTime = 0
    demoVideo.value.play()
  }

  trackEvent({
    name: "homepage demo video played",
    userID: supabaseUser.value?.id || null
  })

}


const examples = [
  {
    style: "Pixie",
    style_link: "hairstyles/pixie",
    hair_changer_queryparam: "styles=Pixie",
    src: "/AAA-hairstyle_images/homepage/example-female-pixie?updatedAt=1721248938323",
    alt: "Virtual AI try on example of pixie cut for women"
  },
  {
    style: "Disheveled Bob",
    style_link: "hairstyles/bob-disheveled",
    hair_changer_queryparam: "styles=Bob+-+disheveled",
    src: "/AAA-hairstyle_images/homepage/example-female-dishevled-bob?updatedAt=1721248938309",
    alt: "Virtual AI try on example of disheveled bob for women"
  },
  {
    style: "Bridal",
    style_link: "hairstyles/bridal-hairstyle",
    hair_changer_queryparam: "styles=Bridal+Style",
    src: "/AAA-hairstyle_images/homepage/example-female-wedding-bridal-AI-style?updatedAt=1721249489147",
    alt: "Virtual AI try on example of bridal hair style for women"
  },
  {
    style: "Messy Bun",
    style_link: "hairstyles/messy-bun",
    hair_changer_queryparam: "styles=Messy+Bun",
    src: "/AAA-hairstyle_images/homepage/female-example-messy-bun?updatedAt=1721250985526",
    alt: "Virtual AI try on example of messy bun for women"
  },
  {
    style: "Braids",
    hair_changer_queryparam: "styles=Box+Braids+Long",
    style_link: "hairstyles/box-braids-long",
    src: "/AAA-hairstyle_images/homepage/example-female-braids?updatedAt=1721250056910",
    alt: "Virtual AI try on example of braids for women"
  },
  {
    style: "Bald",
    style_link: "hairstyles/bald",
    hair_changer_queryparam: "styles=Bald",
    src: "/AAA-hairstyle_images/homepage/female-example-bald?updatedAt=1721250503825",
    alt: "Virtual AI try on example of bald for women"
  },
  {
    style: "Disheveled Bob",
    style_link: "hairstyles/bob-disheveled",
    hair_changer_queryparam: "styles=Bob+-+disheveled",
    src: "/AAA-hairstyle_images/homepage/example-female-blonde-disheveled-bob?updatedAt=1721252650642",
    alt: "Virtual AI try on example of blonde disheveled for women"
  },
  {
    style: "White French Bob",
    style_link: "hairstyles/french-bob",
    hair_changer_queryparam: "styles=Bob+-+French",
    src: "/AAA-hairstyle_images/homepage/female-example-white-french-bob?updatedAt=1721252847174",
    alt: "Virtual AI try on example of white french bob for women"
  },
  {
    style: "Crew Cut",
    style_link: "hairstyles/crew-cut",
    hair_changer_queryparam: "styles=Crew+Cut",
    src: "/AAA-hairstyle_images/homepage/crew-cut-male-try-on?updatedAt=1721252143082",
    alt: "Virtual AI try on example of crew cut for men"
  },
  {
    style: "French Crop",
    hair_changer_queryparam: "styles=French+Crop",
    style_link: "hairstyles/french-crop",
    src: "/AAA-hairstyle_images/homepage/man-french-crop-example?updatedAt=1721251614546",
    alt: "Virtual AI try on example of french crop for men"
  },
  {
    style: "Pompadour",
    style_link: "hairstyles/pompadour",
    hair_changer_queryparam: "styles=Pompadour",
    src: "/AAA-hairstyle_images/homepage/man-virtual-try-on-example-pompadour?updatedAt=1721251703165",
    alt: "Virtual AI try on example of pompadour for men"
  },
  {
    style: "Chalamet Flow",
    style_link: "hairstyles/timothy-chalamet-flow",
    hair_changer_queryparam: "styles=Chalamet+Flow",
    src: "/AAA-hairstyle_images/homepage/flow-hairsrtyle-for-men-virtual-try-on?updatedAt=1721252023271",
    alt: "Virtual AI try on example of flow for men"
  }
]
if (cachedStyles.data.value) {
  styles.value = cachedStyles.data.value
} else {
  const { data: availableStyles } = await useFetch('/api/styles?additional_fields=all', {
    key: 'styles',
    transform: (response) => {
      return response.data
    }
  })

  // handleHairstyleTags(availableStyles.value)

  styles.value = availableStyles.value
}

// a computed value
// const stripeElements = computed(() => $stripe.elements)

const subDomain = useSubdomain()
const { data } = await useAsyncData('home', () => queryContent('/blog').limit(3).where({ featured: true }).sort({ postNumber: -1, $numeric: true }).find())
// console.log(data.value)
if (data.value) {
  featuredPosts.value = data.value
}

onMounted(() => {
  console.log("index mounted")
  // if(subDomain.value)
  //   console.log("subDomain:", subDomain.value)
  // navigateTo('/hair')

})

// const buyNowClicked = () => {
//   console.log("buyNowClicked")

//   // if we don't have a user, prompt for login
//   if(!supabaseUser.value) {
//     console.log("No user, prompting for login")
//     authModal.value.show = true
//     return
//   } else {
//     console.log("User is logged in")
//     creditTopUpModal.value.show = true
//     creditTopUpModal.value.data.mode = "all-styles-package"
//     creditTopUpModal.value.data.result.success = null
//     creditTopUpModal.value.data.result.amount = null

//   }
// }

// const imagesWithMasks = computed(() => {
//   return Object.values(images.value).filter(el => el.mask_url)
// })

// watch(supabaseUserData, (newData, oldData) => {
//   console.log('supabaseUserData changed:', supabaseUserData.value);

//   if (supabaseUserData.value) {
//     if (supabaseUserData.value?.images) {
//       images.value = supabaseUserData.value.images
//     }
//   }
// })

// async function topupModalClosed() {
//   creditTopUpModal.value.show = false
//   console.log("Handling topupmodal closed", creditTopUpModal.value.data)
// }

// const stripeModalOpened = () => {
//   console.log("stripeModalOpened")
//   initStripeElements()
// }
const styleAltText = (style) => {

  let altString = `AI hairstyle preview for ${style.description} hair style`

  return altString

}

// const retreiveClientSecret = async () => {

//   // fetch the client secret from the API
//   const res = await fetch('/api/create-payment-intent', {
//     method: 'POST',
//     body: JSON.stringify({
//       amount: 1499
//     })
//   });

//   const json = await res.json();

//   console.log("json:", json);``

//   if(json.error) {
//     console.log("json.error:", json.error)
//     return
//   }

//   if(json.data.clientSecret) {
//     clientSecret.value = json.data.clientSecret
//   } else {
//     console.log("No client secret :(")
//   }

// }

// const initStripeElements = async () => {

//   console.log("initiating strip elements")

//   const localStripe = Stripe(useRuntimeConfig().public.STRIPE_PUBLISHABLE_KEY);

//   await retreiveClientSecret()

//   const appearance = { /* appearance */ }
//   const options = { 
//     layout: {
//       type: 'tabs',
//       defaultCollapsed: false,
//     },
//     mode: "payment",
//     currency: "usd",
//     amount: 1499,
//   }
//   // const elements = stripe.elements(options)
//   // const expressCheckoutElement = elements.create('expressCheckout', appearance)
//   // expressCheckoutElement.mount(stripeElementsTest.value)
//   const elements = localStripe.elements({ clientSecret: clientSecret.value, appearance });
//   const paymentElement = elements.create('payment', options);
//   paymentElement.mount(stripeElementsTest.value);

//   setTimeout(() => {
//     watchStripe()
//   }, 5000);
// }


// function watchStripe() {

//   console.log("Watching stripe for confrim event: ", stripeElementsTest.value)

//   stripeElementsTest.value.on('confirm', async (event) => {
//     const { error: submitError } = await elements.submit();
//     if (submitError) {
//       stripe.value.error = submitError;
//       return;
//     }

//     // Create the PaymentIntent and obtain clientSecret
//     const res = await fetch('/api/create-payment-intent', {
//       method: 'POST',
//     });

//     const { client_secret: clientSecret } = await res.json();

//     const { error } = await stripe.confirmPayment({
//       // `elements` instance used to create the Express Checkout Element
//       elements,
//       // `clientSecret` from the created PaymentIntent
//       clientSecret,
//       confirmParams: {
//         return_url: useRuntimeConfig().public.STRIPE_SUCCESS_URL,
//       },
//     });

//     if (error) {
//       // This point is only reached if there's an immediate error when
//       // confirming the payment. Show the error to your customer (for example, payment details incomplete)
//       stripe.value.error = error;
//     } else {
//       // The payment UI automatically closes with a success animation.
//       // Your customer is redirected to your `return_url`.
//       toast.success('Payment succeeded!');
//     }
//   });

// }

function selectGender(model) {

  selectedGender.value = model

  if (supabaseUser.value) {
    trackEvent({
      name: "gender selected",
      userID: supabaseUser.value.id,
      properties: {
        selectedGender: model
      }
    })
  }
}

// const ogImageOptions = {
//   title: 'AI Hairstyles for male and female - try on any style in seconds',
//   component: 'Default',
//   image: 'https://ik.imagekit.io/aicosmetic/ogimage.png?updatedAt=1691108534569'
// }
// a. Use the Composition API
// defineOgImage(ogImageOptions)

useSeoMeta({
  title: 'AI hairstyles - try on any hairstyle with AI',
  ogTitle: 'AI hairstyles - try on any hairstyle with AI',
  description: 'AI hairstyles for men and women - try new styles and colours you\'d never even thought of before',
  ogDescription: 'AI hairstyles for men and women - try new styles and colours you\'d never even thought of before',
  ogImage: 'https://ik.imagekit.io/aicosmetic/og-image.png',
  twitterCard: 'Try hairstyles with AI - AI hairstyles for men and women'
})

// just pass a jsonld object for static jsonld
useJsonld([
  {
    '@context': 'https://schema.org',
    "@type": "SoftwareApplication",
    "name": "AI hairstyles",
    "operatingSystem": "ANDROID, OS X, WINDOWS, IOS, LINUX, CHROME_OS, WEB",
    "applicationCategory": "WebApplication",
    "offers": {
      "@type": "Offer",
      "price": "6.99",
      "priceCurrency": "USD"
    }
  },
  {
    "@context": "https://schema.org",
    "@type": "Organization",
    "url": "https://www.aihairstyles.com",
    "logo": "https://ik.imagekit.io/aicosmetic/logo-square.png"
  }
]);

const packages = computed(() => {
  // returns an array of packages based on the products object, where the product has a type of "package"
  // iterate over each object in products, and only return the ones where the type is "package"
  let tempArray = []
  Object.keys(products.value).forEach(key => {
    if (products.value[key].type === "package") {
      tempArray.push(products.value[key])
    }
  })

  // sort by price, lowest first  
  tempArray.sort((a, b) => {
    return a.price - b.price
  })

  return tempArray
})

const filteredStyles = computed(() => {
  // filter styles based on the selected gender of the user

  let returnArray = []



  if (selectedGender.value) {

    returnArray = styles.value.filter(style => {
      return style.sex === "unisex" || style.sex === selectedGender.value
    })
  } else {
    returnArray = styles.value
  }

  // console.log('returnArray:', returnArray);

  return returnArray


})

</script>